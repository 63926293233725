var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", { staticClass: "mb-16" }, [
        _vm._v(_vm._s(_vm.$t("admin.dashboard.title")))
      ]),
      _c("menu-card", {
        staticClass: "mb-base",
        attrs: { pannels: this.pannels }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }