<template>
  <div>
    <h3 class="mb-16">{{ $t('admin.dashboard.title') }}</h3>
    <menu-card
      class="mb-base"
      :pannels="this.pannels"
    />
  </div>
</template>

<script>
import MenuCard from "@/modules/Shared/Components/menu-card/MenuCard.vue";
import { generateMenu } from "@/modules/Shared/Helpers/menuHelper.js";
import adminMenuItems from "@/modules/Admin/Pages/adminMenuItems";

export default {
  components: {
    MenuCard,
  },
  data() {
    return {
      pannels: [],
    }
  },
  async created() {
    this.pannels = generateMenu(adminMenuItems, null, [])
  }
};
</script>
